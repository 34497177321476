@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import 'react-toastify/dist/ReactToastify.min.css';

body {
  margin: 0;
  font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #212124;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@layer utilities {
  .skeleton {
    @apply bg-zinc-200;
    @apply animate-pulse;
    @apply inline-block;
    @apply rounded-full;
    @apply h-4;
  }
  .skeleton-lighter {
    @apply bg-zinc-200/50;
    @apply animate-pulse;
    @apply inline-block;
    @apply rounded-full;
    @apply min-h-2;
  }
}
